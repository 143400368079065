<template>
  <div>
    <HomePageTop/>
    <div class="paymentSuccessPage">
      <div class="EmployeesAccountTitle flexAndCenter">
        <div class="fontSize20 color333 fontWeightBold">支払い失敗しました</div>
      </div>
      <div class="paymentSuccessExplainContainer flexAndCenterAndCenter">
        <img class="successImg" src="../../assets/paypalPaymentErrorIcon.png"/>
        <div>
          入金画面生成エラー、お手数をおかけしますが、画面を閉じて、再度操作をお願いいたします。
        </div>
        <div class="btnListContainer flexAndCenter">
          <div @click="$fun.routerToPage('/')">{{ $fanyi("返回首页") }}</div>
          <div @click="$fun.routerToPage('/user/index')">{{ $fanyi("个人中心") }}</div>
          <div @click="$fun.routerToPage('/user/churujinList')">{{ $fanyi("账单") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";

export default {
  components: {
    HomePageTop
  },
  data() {
    return {
      priceInfo: {}
    }
  },
  created() {
  },
}
</script>

<style lang="scss" scoped>
.paymentSuccessPage {
  width: 1400px;
  margin: 20px auto 0;
  background: #fff;

  .EmployeesAccountTitle {
    background: #FFFFFF;
    height: 73px;
    justify-content: space-between;
    padding: 0 26px;

    border-radius: 10px 10px 0 0;
    border-bottom: 1px solid #DFDFDF;
  }

  .paymentSuccessExplainContainer {
    margin-top: 60px;
    flex-direction: column;
    padding-bottom: 156px;

    img {
      width: 80px;
      height: 80px;
      margin-bottom: 26px;
    }

    .paymentSuccessText {
      color: #333333;
      font-size: 18px;
      margin-bottom: 51px;
      text-align: center;
    }

    .paypalPaymentSuccessExplainText {
      font-size: 18px;
      color: #333333;
      text-align: center;
    }

    .footerText {
      color: #333333;
      font-size: 18px;
      margin: 30px 0;
      text-align: center;
    }

    .paymentSuccessMessageContainer {
      width: 500px;
      height: 120px;
      background: #F9F9F9;
      border-radius: 10px;
      padding: 35px 0 0 106px;

      div {
        color: #333333;
        font-size: 18px;
      }

      .flexAndCenter {
        margin-left: 35px;

        div {
          color: #B4272B;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .btnListContainer {
      margin-top: 60px;
      width: 500px;
      justify-content: space-between;

      div {
        width: 150px;
        height: 38px;
        background: #FFFFFF;
        border: 1px solid #B4272B;
        border-radius: 3px;
        line-height: 38px;
        text-align: center;
        cursor: pointer;
        color: #B4272B;
        font-size: 14px;
      }

      div:last-child {
        color: #FEFEFE;
        background: #B4272B;
      }
    }
  }
}
</style>
